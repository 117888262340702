<div *ngIf="isOpen">
  <div class="modal-header panel-primary p-0">
    <h3 class="modal-title float-left w-100">
      <div class="float-left add-location-text w-100">
        <span class="float-left">
          {{ this.parentObj?.title }}
          <span class="pl-1"> {{ 'AssignedFacilities' | translate }}</span>
        </span>

        <span
          class="close-btn cross-icon float-right text-right pt-0 pr-0"
          (click)="cancel()"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>        
      </div>
    </h3>
  </div>

  <div class="modal-body" id="quickAddFacilityDiv">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <ng-select
          [disabled]="!isAuthorized"
          [multiple]="true"
          id="assignedFacility"
          [clearable]="false"
          [closeOnSelect]="false"
          [(ngModel)]="selectedFacility"
          [placeholder]="'Select Facility' | translate"
          name="assignedFacility"
          [items]="facilities"
          bindValue="id"
          required
          (change)="
            updateFacility(selectedFacility);
            onAssignedFacilityChange(selectedFacility)
          "
          class="input-element form-input select-dropdown-menu user-align-dropdown select-facility"
          [clearSearchOnAdd]="true"
          [searchFn]="searchList"
        >
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value select_triggerrow">
              <span class="ng-value-label">
                {{
                  facilities?.length == selectedFacility?.length
                    ? 'All'
                    : items.length
                }}
                facility(s)
              </span>
            </div>
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
          > 
            <input
              class="checkbox-input ml-0 mb-0 mt-0 mr-1"
              id="item-{{ index }}"
              type="checkbox"
              [ngModel]="item$.selected"                
            />
            <label for="termCondition" class="checkbox-label-text" (click)="onCheckAssignFacilities(item.id)">
              {{ item.name }}
            </label>
          </ng-template>
        </ng-select>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
        <div class="custom-dropdown-menu">
          <div class="form-group mt-2">
            <div class="form-panel">
              <div>
                <span
                  class="float-left added-location-text pl-3 pr-3"
                  style="width: 100%"
                  [innerText]="'AddedFacility' | translate"
                  >Added Facility</span
                >

                <div class="dropdown-overflow float-left">
                  <a
                    class="dropdown-item pl-3 pr-2"
                    *ngFor="
                      let data of itemData?.assignedFacility;
                      let idx = index
                    "
                  >
                    <div class="float-left" style="width: 100%">
                      <span class="task-name product-name" *ngIf="data.id !== -1">
                        {{ data?.name }}</span
                      >
                    </div>
                    <span *ngIf="data.id !== -1"
                      [hidden]="
                        !isAuthorized || itemData?.assignedFacility?.length == 1
                      "
                      (click)="
                        showConfirmationModal(itemData?.assignedFacility, idx)
                      "
                      class="cross-icon"
                    >
                      <img
                        src="assets/images/old-img/cross-icon.svg"
                        alt=""
                        height="17"
                        width="17"/>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
