import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/constants/endpoints';
import { LoaderService } from 'src/app/services/common-helper-service/loder.service';
import { RequestService } from 'src/app/services/request.service';
import { StorageService } from 'src/app/services/storage.serviceV2';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmService } from '../confirm/confirm.service';
import { TranslateService } from '@ngx-translate/core';
import { TurfService } from 'src/app/services/turf.service';
import { searchDropDown } from 'src/app/services/api/common-helper.service';

@Component({
  selector: 'app-quick-assigned-facility',
  templateUrl: './quick-assigned-facility.component.html',
  styleUrls: ['./quick-assigned-facility.component.scss'],
})
export class QuickAssignedFacilityComponent implements OnInit {
  facilities: any = [];
  selectAll: boolean = false;
  selectedFacility: any = []
  token;

  public parentObj: any = {};
  itemData: any = {};

  public isOpen: boolean = false;

  @Output() action = new EventEmitter();

  isAuthorized: boolean = true;

  searchList = searchDropDown;
  constructor(
    private storageService: StorageService,
    private requestService: RequestService,
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    public modalRef: BsModalRef,
    private confirmService: ConfirmService,
    private translate: TranslateService,
    private turfService: TurfService
  ) {
    this.token = {
      authorization: this.storageService.authorizationToken,
    };
    if(this.storageService.allFacilityList && this.storageService.allFacilityList?.length) {
      this.facilities = this.storageService.allFacilityList;
      this.facilities?.unshift({ id: -1, name: 'Select All' });
      if(this.storageService.user) {
        this.isAuthorized = this?.turfService?.isAuthorized('plan/staff', 'edit');
      }
    }

  }

  lastValue = 0;
  onAssignedFacilityChange(event) {
    let allObj = event.find((p) => p == -1);

    if (allObj == -1 ) {
      this.lastValue = 1;
      this.selectedFacility = [];
      this.itemData.assignedFacility = [];
      if (!Array.isArray(this.facilities)) this.facilities = [];
      if (event.length === 1 && event[0] === -1) {
        this.facilities.forEach((fac) => {
          this.itemData.assignedFacility.push(fac);
          this.selectedFacility.push(fac.id);
          this.lastValue = 0
        });
      } else if(!this.selectAll){
      this.facilities.forEach((fac) => {
        if (fac.id != -1 &&event.includes(fac.id)) {
          this.itemData.assignedFacility.push(fac)
          this.selectedFacility.push(fac.id);
          this.lastValue = 0
        } 
      });
      } else {
      this.facilities.forEach((fac) => { 
          this.itemData.assignedFacility.push(fac)
          this.selectedFacility.push(fac.id);
          this.lastValue = 0
        })
     }
    }

    if (allObj == undefined) {
      if (this.lastValue) {
        this.selectedFacility = [];
        this.itemData.assignedFacility = []
        this.lastValue = 0;
      } else {
        if (event.length == this.facilities.length - 1) {
          this.selectedFacility = [];

          if (!Array.isArray(this.facilities)) this.facilities = [];

          this.facilities.forEach((fac) => {
            this.selectedFacility.push(fac.id);
          });
          this.lastValue = 1;
        }
        if (event.length < this.facilities.length - 1) {
          this.selectedFacility = [];
          this.selectedFacility = event;
        }
      }
    }
    if(this.selectAll && event.length === 3){
      this.selectedFacility = [];
      this.itemData.assignedFacility = []
      this.lastValue = 0
    }

    if(event.includes(-1) && this.selectAll) {
      this.selectedFacility = []
      this.itemData.assignedFacility = []
      this.facilities.forEach((fac) => { 
        this.itemData.assignedFacility.push(fac)
        this.selectedFacility.push(fac.id);
        this.lastValue = 0
      });
    }

  }

  ngOnInit(): void {
    this.itemData = this.parentObj?.data;
    this.isOpen = this.parentObj?.data?.id ? true : false;
    this.selectedFacility = []
    this.itemData?.assignedFacility.forEach(fac => {
      this.selectedFacility.push(fac.id)
    })
    if (
      this.storageService?.user?.id &&
      this.storageService.allFacilityList?.length &&
      this.storageService.allFacilityList?.length ==
      this.selectedFacility?.length
    ) {
      this.selectedFacility.unshift(-1);
    }
  }

  createAssignedFacilityList(data) {
    let assignedFacility = data.assignedFacilityId.split(',').map(Number);
    data.assignedFacility = this.facilities.reduce(
      (arr, itm) => (assignedFacility.includes(itm.id) ? arr.concat(itm) : arr),
      []
    );

    this.selectedFacility = assignedFacility;

    return data.assignedFacility;
  }

  onCheckAssignFacilities(id) {
    this.selectAll = false
    if(id === -1) {
      this.selectAll = true
    }  
  }

  handleError(error) {
    console.log(error);
    this.loaderService.hideLoader();
  }

  showConfirmationModal(item, idx) {
    let textMessage = `DoYouWantToUnassignedTheFacilityThisWillRemovetheWorkerFromAllFutureTaskIfThereIsNotActiveTask`;
    this.translate.get(textMessage).subscribe((message) => {
      this.confirmService.show(
        {
          message: `${message}`,
        },
        (c) => {
          if (c) {
            this.removeFacility(item, idx);
          }
        }
      );
    });
  }

  removeFacility(item, idx) {
    item.splice(idx, 1);
    let data: any = [];
    item.forEach((fac) => {
      data.push(fac.id);
    });
    data = data.join(',');

    this.loaderService.showLoader();

    this.requestService
      .send('PUT', `${endpoints.users}/${this.itemData.id}`, {
        body: {
          userFacility: data,
        },
        headers: this.token,
      })
      .then((response: any) => {
        this.loaderService.hideLoader();
        this.translate.get('UpdatedSuccessfully').subscribe((message) => {
          this.toastrService.success(
            message,
            this.loaderService.notificationTitle.success
          );
        });
        this.itemData.assignedFacility = this.createAssignedFacilityList(
          response
        );
      })
      .catch((error) => {
        this.handleError(error);
      });
       this.selectedFacility = []
       this.lastValue = 0
       item.forEach((item)=>{
         if(item.id != -1){
         this.selectedFacility.push(item.id)
         }
       })
  }

  updateFacility(selectedFacility) {
    // this.cancel(data)
    let data = selectedFacility.join(',');
    data = JSON.parse('[' + data + ']');
    this.itemData.assignedFacility = [];
    this.selectedFacility = [];

    if (!Array.isArray(this.facilities)) this.facilities = [];

    this.facilities.forEach((fac) => {
      if (data.includes(fac.id)) {
        this.itemData?.assignedFacility.push(fac);
        this.selectedFacility.push(fac.id);
      }
    });
  }

  cancel() {
    if(this.selectedFacility.length){
      this.isOpen = false;
      this.modalRef.hide();
      this.action.emit(this.selectedFacility);
    }
    else{
      this.translate.get('SelectMinimumOneFacility').subscribe((message) => {
        this.toastrService.warning(
          message,
          this.loaderService.notificationTitle.warning
        );
      });
    }
  }
}
